<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        Details
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-sm-3 col-xs-3 p-10">
                <ng-select  [(ngModel)]="selectable" (ngModelChange)="dataChanged($event)">
                    <ng-option value="" selected="selected">---- Select Tablename ----</ng-option>
                    <ng-option *ngFor="let table of Tableslist;trackBy:trckbytableid" [value]="table.tableid">{{table.name}}</ng-option>
                </ng-select>
            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <input type="text" placeholder="Enter RoundId" class="form-control" name="From date" [(ngModel)]="roundId" autocomplete="off">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-xs-6 p-10">
                <textarea type="text" placeholder="Round JSON data" class="form-control" [disabled]="nonOlga" name="" [(ngModel)]="roundData" autocomplete="off"></textarea>
            </div>
        </div>
        <div>
            <a class="btn btn-info btn-sm" title="Store Result" (click)="storeResult()"><span>Store Result</span></a>
            <a class="btn btn-info btn-sm" title="Check Result" (click)="checkRoundResult()"><span>Check Result</span></a>
            <a class="btn btn-danger btn-sm" title="Check Result" (click)="clearspace()"><span>Clear</span></a>
        </div>
        <pre>
            {{ roundResultdata | json }}
        </pre>
    </mat-card-content>
</mat-card>