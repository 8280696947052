import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SidenavService } from '../Services/sidenav.service';
import { TokenService } from '../Services/token.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  mobileQuery: MediaQueryList;
  showElement: boolean;
  private _mobileQueryListener: () => void;
  constructor(changeDetectorRef: ChangeDetectorRef,private tokenService:TokenService,
    media: MediaMatcher,private sidenavService: SidenavService,) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
   }

  ngOnInit(): void {
    this.myFunction();
    this.tokenService.gettoken().subscribe((resp :any) =>{
      this.tokenService.setMMToken(resp.auth);
    })
  }

  myFunction() {
      this.showElement = true;
      setTimeout(function() {
        // console.log(this.showElement); // Will result in undefined;
        this.showElement = false; // Here, this, reference to context of the function wrapper
      }, 2000);
  }

}
