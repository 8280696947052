import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './Services/auth.gaurd';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { ScoreSetupComponent } from './score-setup/score-setup.component';
import { SessionUpdComponent } from './session-upd/session-upd.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { WhitelistComponent } from './whitelist/whitelist.component';
import { TKfancyComponent } from './tkfancy/tkfancy.component';
import { AmanfancyComponent } from './amanfancy/amanfancy.component';
import { StoreAllapiComponent } from './store-allapi/store-allapi.component';
import { LaserwhitelistComponent } from './laserwhitelist/laserwhitelist.component';
import { DreamwhitelistComponent } from './dreamwhitelist/dreamwhitelist.component';
import { PremiumComponent } from './premium/premium.component';
import { LasermaintenanceComponent } from './lasermaintenance/lasermaintenance.component';
import { OlgacasinoComponent } from './casino_results/olgacasino/olgacasino.component';
import { AgentmanageComponent } from './agentmanage/agentmanage.component';
import { AwcwhitelistComponent } from './awcwhitelist/awcwhitelist.component';
import { PendingmarketsComponent } from './pendingmarkets/pendingmarkets.component';
import { PendingcasinoComponent } from './pendingcasino/pendingcasino.component';
import { PendingfancyComponent } from './pendingfancy/pendingfancy.component';
import { SettlementComponent } from './settlement/settlement.component';
import { ManagementComponent } from './management/management.component';
import { BmResultComponent } from './bm-result/bm-result.component';
import { FancyTypeComponent } from './fancy-type/fancy-type.component';
const routes: Routes = [
  { path: "login", component: LoginComponent, data: { title: 'LOGIN' } },
  {
    path: "", component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: UserInfoComponent, data: { title: 'Users' } },
      { path: "home", component: UserInfoComponent, data: { title: 'Home' } },
      { path: "whitelist", component: WhitelistComponent, data: { title: 'VRNL Whitelist' } },
      { path: "laserwhitelist", component: LaserwhitelistComponent, data: { title: 'Laser Whitelist' } },
      { path: "dreaminplaywhitelist", component: DreamwhitelistComponent, data: { title: 'Laser Whitelist' } },
      { path: "scoresetup", component: ScoreSetupComponent, data: { title: 'Score' } },
      { path: "bm-result", component: BmResultComponent, data: { title: 'BM' } },
      { path: "fancy-type", component: FancyTypeComponent, data: { title: 'FC' } },
      { path: "fancysettle", component: SessionUpdComponent, data: { title: 'Fancy' } },
      { path: "fancysettle/:selectedUid", component: SessionUpdComponent, data: { title: 'Fancy' } },
      { path: "premiumsettle", component: PremiumComponent, data: { title: 'Premium' } },
      { path: "tkfancysettle", component: TKfancyComponent, data: { title: 'TKFancy' } },
      { path: "tkfancysettle/:selectedUid", component: TKfancyComponent, data: { title: 'TKFancy' } },
      { path: "agentmanage", component: AgentmanageComponent, data: { title: 'Agent List' } },
      { path: "awcwhitelist", component: AwcwhitelistComponent, data: { title: 'AWC WhiteList' } },
      { path: "amanfancysettle", component: AmanfancyComponent,data: {title: 'Aman Fancy'}},
      { path: "StoreApi", component: StoreAllapiComponent, data: { title: 'StoreApi' } },
      { path: "casinoresult", component: OlgacasinoComponent, data: { title: 'Casino' } },
      { path: "lasermaintenance", component: LasermaintenanceComponent, data: { title: 'StoreApi' } },
      { path: "pendingmarkets", component: PendingmarketsComponent, data: { title: 'Pending Markets' } },
      { path: "pendingcasino", component: PendingcasinoComponent, data: { title: 'Pending Casino' } },
      { path: "pendingfancy", component: PendingfancyComponent, data: { title: 'Pending Fancy' } },
      { path: "settlement", component: SettlementComponent, data: { title: 'Settlement' } },
      { path: "management", component: ManagementComponent, data: { title: 'Mangement' } },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true , relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
