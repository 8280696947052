<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        Settlement

    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-sm-3 col-xs-3 p-10">
                <input type="search" placeholder="Select Date..." class="form-control" name="From date" bsDatepicker
                    [(ngModel)]="matchDate" autocomplete="off"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-default', isAnimated: true }">

            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <select class="form-control" (change)="filterSport($event)">
                    <option [value]="0" selected>Select Sport</option>
                    <option [value]="4">Cricket</option>
                    <option [value]="1">Soccer</option>
                    <option [value]="2">Tennis</option>
                    <option [value]="52">Kabaddi</option>
                  </select>
            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <ng-select id="selectSport" (change)="selectSport($event)" [(ngModel)]="selectedSport">
                    <ng-option value="0" selected=""> Select Event </ng-option>
                    <ng-option *ngFor="let sport of sportsEventIds" [value]="sport">
                        {{ sport.name }}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <a class="btn btn-warning btn-sm m-1" title="Games" (click)="getGames()">
                    Get History
                </a>
            </div>

        </div>
        <div class="row" *ngIf="marketList && marketList.length">

            <div class="col-sm-3 col-xs-3 p-10">
                <ng-select id="selectMarket" (change)="selectMarket($event)" [(ngModel)]="selectedMarket">
                    <ng-option value="0" selected=""> Select Market </ng-option>
                    <ng-option *ngFor="let market of marketList" [value]="market">
                        {{ market.eventName }} > {{ market.marketName }}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <a class="btn btn-warning btn-sm m-1" title="Games" (click)="settleMarket()" style="margin-right: 8px;">
                    Settle Market
                </a>
                <a class="btn btn-warning btn-sm m-1" title="Games" (click)="confirmVoid()" data-toggle="modal" style="float: left;" data-target="#Confirmcancelmodal">
                    Void Market Bets
                </a>
            </div>

        </div>
        <hr>
        <mat-accordion>
            <mat-expansion-panel [id]="event.eventId" *ngFor="let event of virtualGames; trackBy: trackByEventId">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h6 class="event-name">{{ event.eventName }}</h6> &nbsp; &nbsp;
                        <div>
                            {{ event.startTime | date: "dd-MM-yyyy HH:mm:ss" }}
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bookmaker">
                    <span>{{ event.bookMakerMarket.marketName }}</span>
                    <span class="spacer"></span>
                    <select #bookResult name="bookResult" class="form-control">
                        <option value="0">Selection</option>
                        <option [value]="selection.selectionId" *ngFor="
                      let selection of event.bookMakerMarket.selections;
                      trackByMarketId
                    ">
                            {{ selection.selectionName }}
                        </option>
                    </select>
                    <button class="btn" (click)="
                    settleBook(event.bookMakerMarket.marketId, bookResult.value)
                  ">
                        Settle Book
                    </button>
                </div>
                <div class="fancy" *ngFor="let fancy of event.fancyMarkets">
                    <span>{{ fancy.marketName }}</span>
                    <span class="spacer"></span>
                    <input type="text" name="fancyResult" #fancyResult placeholder="runs" />
                    <button class="btn btn-info btn-sm" (click)="settleFancy(fancy.marketId, fancyResult.value)">
                        Settle Fancy
                    </button>
                </div>
            </mat-expansion-panel>
         </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel  [id]="event.eventId" *ngFor="let event of Kabaddigames; trackBy: trackByEventId">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h6 class="event-name">{{ event.eventName }}</h6> &nbsp; &nbsp;
                        <div>
                            {{ event.startTime | date: "dd-MM-yyyy HH:mm:ss" }}
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bookmaker">
                    <span>{{ event.bookMakerMarket.marketName }}</span>
                    <span class="spacer"></span>
                    <select #bookResult name="bookResult" class="form-control">
                        <option value="0">Selection</option>
                        <option [value]="selection.selectionId" *ngFor="
                        let selection of event.bookMakerMarket.selections;
                      trackByMarketId
                    ">
                    {{ selection.selectionName }}
                        </option>
                    </select>
                    <button class="btn" (click)="
                    settlekabaddi(event.bookMakerMarket.marketId, bookResult.value)">
                        Settle Book
                    </button>
                </div>
                <div class="fancy" *ngFor="let fancy of event.fancyMarkets">
                    <span>{{ fancy.marketName }}</span>
                    <span class="spacer"></span>
                    <input type="text" name="fancyResult" #fancyResult placeholder="runs" />
                    <button class="btn btn-info btn-sm"(click)="settleFancy(fancy.marketId, fancyResult.value)">
                        Settle Fancy
                    </button>
                </div>
            </mat-expansion-panel>
            <div *ngIf="filteredBmMarkets && filteredBmMarkets.length > 0; else noData">
            <mat-expansion-panel [id]="event.gameId" *ngFor="let event of filteredBmMarkets; trackBy: trackByGameId">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h6 class="event-name">{{ event.eventName }}</h6>&nbsp; &nbsp;
                        <div>
                            {{ get24HrsDate(event.startTime) | date: "dd-MM-yyyy HH:mm:ss" }}
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bookmaker">
                    <span>{{ event.marketName }}</span>
                    <span class="spacer"></span>
                    <div class="padcls">
                    <select #TnplBmResult name="bookResult" class="form-control">
                        <option value="0">Selection</option>
                        <option  *ngFor="
                        let selection of event.selections;
                          let $index = index;
                          trackByMarketId
                        "
                        [value]="selection.selId">
                        {{ selection.name }}
                        </option>
                        <option value="-1">
                            Void market
                        </option>
                    </select>
                    </div>

                    <button class="btn btn-info btn-sm" (click)="openSettleModal(event,TnplBmResult.value)" data-toggle="modal" style="float: left;" data-target="#settleTNPLBm">
                        Settle Book
                    </button>
                </div>
            </mat-expansion-panel>
        </div>
        <ng-template #noData>
            <p>No data found.</p>
          </ng-template>
        </mat-accordion>
        <div id="Confirmcancelmodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Void Market Bets</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <table class="table table-striped table-bordered table-sm row-border hover">
                                <thead>
                                  <tr>
                                    <th width="15%">MarketId</th>
                                    <th>Market</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <td>{{selectedMarket?.marketId}} </td>
                                    <td>{{selectedMarket?.eventName}} > {{selectedMarket?.marketName}} </td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button" (click)="voidMarket()">Void</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div id="settleTNPLBm" class="modal fade" role="dialog" aria-labelledby="settleModal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Settle Book TNL</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i></div>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="popup_form_row">
                            <form (ngSubmit)="settleTNPLBm()" *ngIf="bmData">
                                <div>
                                    <label> Event Name: </label>
                                    <input type="text" class="form-control" [(ngModel)]="bmData.eventName" name="eventName" readonly>
                                </div>
                                <div>
                                    <label> Market ID: </label>
                                    <input type="text" class="form-control" [(ngModel)]="bmData.marketId" name="marketId" readonly>
                                </div>
                              
                                <div>
                                    <label> Result: </label>
                                    <input type="text" class="form-control" [(ngModel)]="resultName" name="result" readonly>
                                </div>
                               
                                <div class="popup_col_12 button-row">
                                    <button mat-raised-button color="primary" type="submit">Settle Book</button>
                                    <button mat-raised-button color="primary" data-dismiss="modal" type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </mat-card-content>
</mat-card>