<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        Store All Api
    </mat-card-header>
    <mat-card-content>
        <div>
            <a class="btn btn-info btn-sm" [ngClass]="{'btnselect':(Allapidata?.fSource==0)}" (click)="Addapi(0)" title="Add Score details"><span>Diamond</span></a>
            <a class="btn btn-info btn-sm" [ngClass]="{'btnselect':(Allapidata?.fSource==1)}"(click)="Addapi(1)" title="Add Score details"><span>TK</span></a>
            <a class="btn btn-info btn-sm" [ngClass]="{'btnselect':(Allapidata?.fSource==2)}"(click)="Addapi(2)" title="Add Score details"><span>Aman</span></a>&nbsp; &nbsp;
            <br class="hideweb"><br class="hideweb">
            <a class="selection"  title="Add Score details" *ngIf="Allapidata?.fSource==0"><span>Currently selected Fany : <button class="btnselect">Diamond</button> </span></a>
            <a class="selection"  title="Add Score details" *ngIf="Allapidata?.fSource==1"><span>Currently selected Fany : <button class="btnselect">TK</button>  </span></a>
            <a class="selection"  title="Add Score details" *ngIf="Allapidata?.fSource==2"><span>Currently selected Fany : <button class="btnselect">Aman</button> </span></a>
        </div>

    </mat-card-content>
</mat-card>