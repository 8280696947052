<div class="app-content content container-fluid">
    <div class="content-wrapper">
        <div class="content-header row">
        </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="container">
                    <div class="col-sm-offset-4 col-sm-5">
                        <div class="card card-login mx-auto text-center">
                            <div class="card-header mx-auto">
                                <!-- <div class="card-title text-xs-center">
                                    <img src="assets/images/logo.png" alt="T20exch"
                                        style="width:80%;margin-left:15px;margin-right: 15px;">
                                </div> -->
                                <span class="logo_title mt-5"><b>LOGIN</b></span>


                            </div>
                            <div class="card-body">
                                <form [formGroup]="loginForm" (ngSubmit)="!loginForm.invalid && login()">
                                    <div class="input-group form-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <mat-icon aria-hidden="false" aria-label="Example home icon">person
                                                </mat-icon>
                                            </span>
                                        </div>
                                        <input type="text" name="Username" formControlName="username"
                                            [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                            class="form-control" placeholder="Enter Username">
                                    </div>

                                    <div class="input-group form-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <mat-icon aria-hidden="false" aria-label="Example home icon">lock
                                                </mat-icon>
                                            </span>
                                        </div>
                                        <input type="password" name="password" formControlName="password"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" class="form-control"
                                            placeholder="Enter password">
                                    </div>
                                    <div class="form-group" style="text-align: initial;">
                                        <input type="checkbox" class="" id="remember-me" aria-label="remember-me">
                                        <label class="rem">Remember Me</label>
                                        <a href="">
                                            <img class="blinker" src="assets/images/android.png">
                                        </a>
                                    </div>

                                    <div class="form-group">
                                        <input type="submit" name="btn" [ngClass]="loginForm.valid ? 'raised' : ''"
                                            [disabled]="!loginForm.valid" value="LOGIN"
                                            class="btn btn-outline-primary login_btn">
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>