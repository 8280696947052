import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AllapiService } from '../Services/allapi.service';
import { NotificationService } from '../Services/notification.service';

@Component({
  selector: 'app-score-setup',
  templateUrl: './score-setup.component.html',
  styleUrls: ['./score-setup.component.scss']
})
export class ScoreSetupComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  date: string;
  TvForm: FormGroup;
  ScoreForm: FormGroup;
  CasinoForm: FormGroup;

  AddmatchdetailsForm: FormGroup;
  ServerswitchForm:FormGroup;
  submitted: boolean = false;
  scorelist: any;
  showscoreform: boolean = false;
  showtvform: boolean = false;
  showcasinoform: boolean = false;

  highlights: any;
  Ticker: any;

  liveTvTypes = ["Diamond", "Bet365", "Skyexch", "Ulivesports", "G-Live", "Youtube", "Others"];

  casinoTypes = [
    { name: "Teenpatti 1-day", gtype: 'teen', rid: "9." },
    { name: "20-20 Teenpatti", gtype: 'teen20', rid: "11." },
    { name: "Teenpatti Test", gtype: 'teen9', rid: "10." },
    { name: "Teenpatti Open", gtype: 'teen8', rid: "22." },
    { name: "Poker 1-Day", gtype: 'poker', rid: "13." },
    { name: "20-20 Poker", gtype: 'poker20', rid: "12." },
    { name: "Poker 6 Players", gtype: 'poker6', rid: "17." },
    { name: "Baccarat", gtype: 'baccarat', rid: "37." },
    { name: "Baccarat 2", gtype: 'baccarat2', rid: "39." },
    { name: "20-20 Dragon Tiger", gtype: 'dt20', rid: "25." },
    { name: "1 Day Dragon Tiger", gtype: 'dt6', rid: "28." },
    { name: "20-20 D T L", gtype: 'dtl20', rid: "32." },
    { name: "20-20 Dragon Tiger 2", gtype: 'dt202', rid: "40." },
    { name: "32 Cards A", gtype: 'card32', rid: "18." },
    { name: "32 Cards B", gtype: 'card32eu', rid: "29." },
    { name: "Andar Bahar", gtype: 'ab20', rid: "14." },
    { name: "Andar Bahar 2", gtype: 'abj', rid: "38." },
    { name: "Lucky 7 - A", gtype: 'lucky7', rid: "24." },
    { name: "Lucky 7 - B", gtype: 'lucky7eu', rid: "36." },
    { name: "Lucky 7 - C", gtype: 'lucky7eu2', rid: "47." },
    { name: "3 Cards Judgement", gtype: '3cardj', rid: "16." },
    { name: "Casino War", gtype: 'war', rid: "31." },
    { name: "Worli Matka", gtype: 'worli', rid: "15." },
    { name: "Instant Worli", gtype: 'worli2', rid: "23." },
    { name: "Amar Akbar Anthony", gtype: 'aaa', rid: "27." },
    { name: "Bollywood Casino", gtype: 'btable', rid: "26." },
    { name: "Queen", gtype: 'queen', rid: "44." },
    { name: "5 Cricket", gtype: 'cricketv3', rid: "41." },
    { name: "Cricket Match 20-20", gtype: 'cmatch20', rid: "35." },
    { name: "Casino Meter", gtype: 'cmeter', rid: "33." },
    { name: "Super Over", gtype: 'superover', rid: "52." },
    { name: "Race20", gtype: 'race20', rid: "46." },
  ];

  serverips = [
    {ip : "136.244.79.114"},
    {ip : "51.195.220.62"},
  ]
  MapfancyForm: FormGroup;

  constructor(private fb: FormBuilder, private notifyService: NotificationService, private Allapi: AllapiService) {
   

  }

  ngOnInit() {
    this.date = moment().format("YYYY-MM-DD");
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      responsive: true,
    };
    this.initUserForm();
    this.getscorelist();


  }

  getscorelist() {
    this.Allapi.GetScorelist().subscribe((resp) => {
      // console.log(resp);
      this.scorelist = resp.result;
      this.rerender();
    });
  }

  addscoreform(stat) {
    if (stat == 1) {
      this.showscoreform = true;
    } else {
      this.showscoreform = false;
    }
    this.showtvform = false;
    this.showcasinoform = false;
  }
  addtvform(stat) {
    if (stat == 1) {
      this.showtvform = true;
    } else {
      this.showtvform = false;
    }
    this.showscoreform = false;
    this.showcasinoform = false;
  }
  addCasinoform(stat) {
    if (stat == 1) {
      this.showcasinoform = true;
    } else {
      this.showcasinoform = false;
    }
    this.showtvform = false;
    this.showscoreform = false;
  }
  deletescoreall() {
    this.Allapi.DeleteAllscores().subscribe((data) => {
      if (data.result.Message == "Data Stored Successfully") {
        this.getscorelist();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result.Message);
      } else {
        this.notifyService.error(data.result.Message);
      }
    })
  }

  deletesinglescore(eventid) {
    this.Allapi.DeleteSinglescores(eventid).subscribe((data) => {
      if (data.result.Message == "Data Stored Successfully") {
        this.getscorelist();
        this.notifyService.success(data.result.Message);
      } else {
        this.notifyService.error(data.result.Message);
      }
    })
  }

  initUserForm() {
    this.TvForm = this.fb.group({
      channelIp: [''],
      channelIp2: [''],
      hdmi: [''],
      channelno: [''],
      matchId: [''],
      sport: ['0', Validators.required],
      event: ['0'],
      type: ['0'],
      livename: ['0'],

    });

    this.ScoreForm = this.fb.group({
      event_id: [''],
      score_id: ['', Validators.required],
      selectsport: ['0', Validators.required],
      selectevent: ['0']
    });

    this.CasinoForm = this.fb.group({
      selectCasino: ['', Validators.required],
      roundId: ['', Validators.required],
    });

    this.AddmatchdetailsForm = this.fb.group({
      competitionId: [''],
      competitionName: [''],
      id: ['', Validators.required],
      name: ['', Validators.required],
      marketId: ['', Validators.required]
    });

    this.ServerswitchForm = this.fb.group({
      selectserver: ['0'],
      serverip: [''],
    });
    this.MapfancyForm = this.fb.group({
      diamondEventId: ['', Validators.required],
      diamondMarketId: ['', Validators.required],
      skyEventId: ['', Validators.required],
    });
  }

  addTv() {
    this.submitted = true;

    if (this.TvForm.invalid) {
      return;
    }
    if (this.TvForm.value) {
      // console.log(this.TvForm.value)
      var tvdata = {
        // "channelIp": (this.TvForm.value.hdmi != '') ? this.TvForm.value.channelIp : null,
        // "channelNo": (this.TvForm.value.channelno != '') ? this.TvForm.value.channelno : null,
        // "hdmi": (this.TvForm.value.hdmi != '') ? this.TvForm.value.hdmi : null,
        // "link": (this.TvForm.value.hdmi != '') ? null : this.TvForm.value.channelIp,
        // "link2": (this.TvForm.value.hdmi != '') ? null : this.TvForm.value.channelIp2,
        // "matchId": (this.TvForm.value.event != '0') ? this.TvForm.value.event : this.TvForm.value.matchId,
        // "type": (this.TvForm.value.matchId && this.TvForm.value.hdmi && this.TvForm.value.channelIp) ? (this.TvForm.value.type != '0') ? this.TvForm.value.type : '1' : (this.TvForm.value.channelno != '') ? (this.TvForm.value.type != '0') ? this.TvForm.value.type : '4' : (this.TvForm.value.type != '0') ? this.TvForm.value.type : '3',
        "channelIp": this.TvForm.value.hdmi ? this.TvForm.value.channelIp : null,
        "channelNo": this.TvForm.value.channelno,
        "hdmi": this.TvForm.value.hdmi,
        "link": !this.TvForm.value.hdmi ? this.TvForm.value.channelIp : null,
        "link2": this.TvForm.value.channelIp2,
        "matchId": this.TvForm.value.matchId,
        "type": this.TvForm.value.type,
        "SportId": this.TvForm.value.sport
      }
    }
    console.log(tvdata);
    this.Allapi.Addtv(tvdata).subscribe((data) => {
      if (data.result.Message == "Data Stored Successfully") {
        // this.initUserForm();
        this.showscoreform = false;
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result.Message);
      } else {
        this.notifyService.error(data.result.Message);
      }
    });
  }

  getTv() {

    if (!this.TvForm.value.matchId) {
      this.fillForms()
      return;
    }
    this.Allapi.Getmatchwise(this.TvForm.value.matchId).subscribe((data) => {
      if (data.result.length > 0) {




        if (this.TvForm.value.livename == "Diamond") {
          this.TvForm.controls['channelIp'].setValue(data.result[0].channelIp);
          this.TvForm.controls['hdmi'].setValue(data.result[0].hdmi);
          this.TvForm.controls['channelIp2'].setValue(null);
          this.TvForm.controls['channelno'].setValue(null);
          if (!data.result[0].channelIp || !data.result[0].hdmi) {
            this.fillForms();
          }

          this.TvForm.controls['type'].setValue(1);
        }
        if (this.TvForm.value.livename == "Bet365") {
          this.TvForm.controls['channelIp'].setValue(data.result[0].link);
          this.TvForm.controls['channelIp2'].setValue(data.result[0].link2);
          this.TvForm.controls['hdmi'].setValue(null);
          this.TvForm.controls['type'].setValue(3);
        }
        if (this.TvForm.value.livename == "Skyexch") {
          this.TvForm.controls['channelIp'].setValue("https://cdn1-sports.qn32.com/live/m" + data.result[0].channelNo + "/playlist.m3u8");
          this.TvForm.controls['channelIp2'].setValue("https://cdn1-sports.qn32.com/live/m" + data.result[0].channelNo + "/playlist.m3u8");
          this.TvForm.controls['hdmi'].setValue(null);
          this.TvForm.controls['type'].setValue(3);

        }
        if (this.TvForm.value.livename == "Ulivesports") {
          this.TvForm.controls['channelIp'].setValue("https://ulivesport.info/" + data.result[0].channelNo + "/tracks-v1a1/mono.m3u8");
          this.TvForm.controls['channelIp2'].setValue("https://ulivesport.info/" + data.result[0].channelNo + "/tracks-v1a1/mono.m3u8");
          this.TvForm.controls['hdmi'].setValue(null);
          this.TvForm.controls['type'].setValue(3);

        }

        if (this.TvForm.value.livename == "G-Live") {
          this.TvForm.controls['channelno'].setValue(data.result[0].channelNo);
          this.TvForm.controls['channelIp'].setValue(null);
          this.TvForm.controls['hdmi'].setValue(null);
          this.TvForm.controls['channelIp2'].setValue(null);

          this.TvForm.controls['type'].setValue(4);
        }
        if (this.TvForm.value.livename == "Youtube") {
          this.TvForm.controls['channelIp2'].setValue(data.result[0].link2);
          this.TvForm.controls['channelIp'].setValue(data.result[0].link);
          this.TvForm.controls['hdmi'].setValue(null);
          this.TvForm.controls['channelno'].setValue(null);
          this.TvForm.controls['type'].setValue(5);
        }
        if (this.TvForm.value.livename == "Others") {
          this.TvForm.controls['channelIp2'].setValue(data.result[0].link2);
          this.TvForm.controls['channelIp'].setValue(data.result[0].link);
          this.TvForm.controls['hdmi'].setValue(null);
          this.TvForm.controls['channelno'].setValue(null);
          this.TvForm.controls['type'].setValue(6);
        }

        if (!data.result[0].link || !data.result[0].link2) {
          this.fillForms();
        }
      }
      else {
        this.fillForms()
      }
    });
  }

  fillForms() {
    if (this.TvForm.value.livename == "Diamond") {
      this.TvForm.controls['channelIp'].setValue("www.livesports1611.com");
      this.TvForm.controls['hdmi'].setValue("HDMI12");
      this.TvForm.controls['type'].setValue(1);
    }
    if (this.TvForm.value.livename == "Bet365") {
      this.TvForm.controls['channelIp'].setValue("https://140522.online/11002/30312319/30312319/stream.m3u8");
      this.TvForm.controls['channelIp2'].setValue("https://140522.online/11002/30312319/30312319/stream.m3u8");
      this.TvForm.controls['channelno'].setValue(null);
      this.TvForm.controls['type'].setValue(3);
    }
    if (this.TvForm.value.livename == "Skyexch") {
      this.TvForm.controls['channelIp'].setValue("https://cdn1-sports.qn32.com/live/m" + this.TvForm.value.channelno + "/playlist.m3u8");
      this.TvForm.controls['channelIp2'].setValue("https://cdn1-sports.qn32.com/live/m" + this.TvForm.value.channelno + "/playlist.m3u8");
      this.TvForm.controls['hdmi'].setValue(null);

      this.TvForm.controls['type'].setValue(3);
    }
    if (this.TvForm.value.livename == "Ulivesports") {
      this.TvForm.controls['channelIp'].setValue("https://ulivesport.info/" + this.TvForm.value.channelno + "/tracks-v1a1/mono.m3u8");
      this.TvForm.controls['channelIp2'].setValue("https://ulivesport.info/" + this.TvForm.value.channelno + "/tracks-v1a1/mono.m3u8");
      this.TvForm.controls['hdmi'].setValue(null);

      this.TvForm.controls['type'].setValue(3);
    }

    if (this.TvForm.value.livename == "G-Live") {
      this.TvForm.controls['channelno'].setValue(this.TvForm.value.channelno);
      this.TvForm.controls['channelIp'].setValue(null);
      this.TvForm.controls['hdmi'].setValue(null);
      this.TvForm.controls['channelIp2'].setValue(null);
      this.TvForm.controls['type'].setValue(4);
    }
    if (this.TvForm.value.livename == "Youtube") {
      this.TvForm.controls['channelIp2'].setValue("youtube code");
      this.TvForm.controls['channelIp'].setValue("youtube code");
      this.TvForm.controls['hdmi'].setValue(null);
      this.TvForm.controls['channelno'].setValue(null);
      this.TvForm.controls['type'].setValue(5);
    }
    if (this.TvForm.value.livename == "Others") {
      this.TvForm.controls['channelIp2'].setValue("other link");
      this.TvForm.controls['channelIp'].setValue("other link here");
      this.TvForm.controls['hdmi'].setValue(null);
      this.TvForm.controls['channelno'].setValue(null);
      this.TvForm.controls['type'].setValue(6);
    }
  }


  changeselectevent(stat) {
    if (stat == 1) {
      this.Allapi.highlights(this.ScoreForm.value.selectsport).subscribe(resp => {
        this.highlights = resp.data;
      })
    } else {
      this.Allapi.highlights(this.TvForm.value.sport).subscribe(resp => {
        this.highlights = resp.data;
      })
    }
  }
  changeevent() {
    // console.log(this.TvForm.value.event);
    this.TvForm.controls['matchId'].setValue(this.TvForm.value.event);

    this.getTv();
  }

  addScore() {
    this.submitted = true;

    if (this.ScoreForm.invalid) {
      return;
    }
    console.log(this.ScoreForm.value);
    var scoredata = {
      "event_id": (this.ScoreForm.value.selectevent != '0') ? this.ScoreForm.value.selectevent : this.ScoreForm.value.event_id,
      "score_id": this.ScoreForm.value.score_id
    }
    // console.log(scoredata)
    this.Allapi.Addscore(scoredata).subscribe((data) => {
      if (data.result.Message == "Data Stored Successfully") {
        this.initUserForm();
        this.getscorelist();
        this.showtvform = false;
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result.Message);
      } else {
        this.notifyService.error(data.result.Message);
      }
    });
  }

  addCasinoRound() {
    // console.log(this.CasinoForm)
    if (this.CasinoForm.valid) {
      let roundId = this.CasinoForm.value.selectCasino + this.CasinoForm.value.roundId;
      console.log(roundId)

      this.Allapi.setCasinoRoundResult(roundId).subscribe(data=>{
        this.notifyService.success(data.result);

        this.initUserForm();
      })
    }
  }

  getTicker() {
    this.Allapi.Getticker().subscribe(resp => {
      this.Ticker = resp.ticker;
    })
  }

  addTicker(ticker) {
    if (ticker == null) {
      ticker = " "
    }
    let data = {
      "ticker": ticker
    }
    this.Allapi.Addticker(data).subscribe(resp => {
      if (resp.result == "ticker saved!") {
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(resp.result);
      } else {
        this.notifyService.error(resp.result);
      }
    })
  }


  getLaserTicker() {
    this.Allapi.GetLaserticker().subscribe(resp => {
      this.Ticker = resp.ticker;
    })
  }

  addLaserTicker(ticker) {
    if (ticker == null) {
      ticker = " "
    }
    let data = {
      "ticker": ticker
    }
    this.Allapi.AddLaserticker(data).subscribe(resp => {
      if (resp.result == "ticker saved!") {
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(resp.result);
      } else {
        this.notifyService.error(resp.result);
      }
    })
  }

  addMatchdetails() {
    let matchdata = {
      // "competitionId": this.AddmatchdetailsForm.value.competitionId,
      // "competitionName": this.AddmatchdetailsForm.value.competitionName,
      "competitionId": -1,
      "competitionName": "Others",
      "id": this.AddmatchdetailsForm.value.id,
      "name": this.AddmatchdetailsForm.value.name,
      "marketId": this.AddmatchdetailsForm.value.marketId
    }    
    this.Allapi.Addmatchdetails(matchdata).subscribe(resp => {
      if (resp.result == "Data saved!") {
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(resp.result);
      } else {
        this.notifyService.error(resp.result);
      }
    })
  }

  Serverswitch(){
    if (this.ServerswitchForm.invalid) {
      return;
    }

    if(this.ServerswitchForm.value.selectserver == '0' && this.ServerswitchForm.value.serverip == '') {
      this.notifyService.error("Select Server ?");
      return;
    }

    let data= {
        "ip": `http://${(this.ServerswitchForm.value.selectserver == '0')?this.ServerswitchForm.value.serverip:this.ServerswitchForm.value.selectserver}:82`,
        "adminIp": `http://${(this.ServerswitchForm.value.selectserver == '0')?this.ServerswitchForm.value.serverip:this.ServerswitchForm.value.selectserver}:81`,
        "sportApi": "http://209.250.242.175:33332",
        "sportSocketApi": "http://209.250.242.175",
        "fancyApi": "https://streamingtv.fun:3440/api",
        "racingApi": "http://136.244.77.249:33333",
        "racingSocketApi": "http://136.244.77.249",
        "exchangeGamesApi": "http://136.244.77.249:43333",
        "casinoApi": "https://streamingtv.fun:3440/api"
    }
    console.log(data);
    this.Allapi.Serverswitchapi(data).subscribe((resp) => {
      if (resp.result == "data saved!") {
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(resp.result);
      } else {
        this.notifyService.error(resp.result);
      }
    });
  }

  ValidateIPaddress(ipaddress) {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
      return (true)
    }
    return (false)
  }

  resetForm() {
    this.initUserForm();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }
  Mapfancy(){
    
    let datafancy={
      diamondEventId: this.MapfancyForm.value.diamondEventId,
      diamondMarketId: this.MapfancyForm.value.diamondMarketId,
      skyEventId: this.MapfancyForm.value.skyEventId,
    }
    if (this.MapfancyForm.invalid) {
      return;
    }
    this.Allapi.Mapfancy(datafancy).subscribe((resp) => {

      if (resp.result == "Data saved!") {
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(resp.result);
      } else {
        this.notifyService.error(resp.result);
      }
    });
  }
}
