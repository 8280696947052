import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { AllapiService } from '../Services/allapi.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-pendingfancy',
  templateUrl: './pendingfancy.component.html',
  styleUrls: ['./pendingfancy.component.scss']
})
export class PendingfancyComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  selectedStatus: any = "0";
  options = [
    {
      status: 0,
      type:"Diamond"
    },
    {
      status: 1,
      type:"Sky"
    },
    {
      status: 2,
      type:"Iframe"
    }];
  fancymarket: any;
  constructor(private allapi:AllapiService) { 
    $(document).on("focus", 'input[type="search"]', function () {
      var ele:any = $('input[type="search"]').val();
      var table = $("#fancytable").DataTable();
      table.search(ele, false, false, false).draw();
    });
  }

  ngOnInit(): void {
    this.pendinfancy();
    this.initDatatable();
  }
  ngAfterViewInit() {

    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
  initDatatable() {
    // console.log('table')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      responsive: true,
      deferRender: true,
    };
  }
  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }
  pendinfancy(){
    this.allapi.pendingFancy(this.selectedStatus).subscribe((res) =>{
      // console.log(res);
      this.fancymarket =res.data;
      this.rerender()

    })
  }

}
