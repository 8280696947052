import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgentmanageService {
  BASEURL = 'https://api2.vrnlofficial.com:15552/api';

  constructor(private http: HttpClient) { }

  AddAgent(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/addAgent`,data);
  }

  getAgentList(userType): Observable<any> {
    return this.http.get(`${this.BASEURL}/getAgentList/${userType}`);
  }

  updateAgent(data): Observable<any> {
    return this.http.put(`${this.BASEURL}/updateAgentDetails`,data);
  }
  deleteAgent(userId): Observable<any> {
    return this.http.delete(`${this.BASEURL}/deleteAgent/${userId}`);
  }
}
