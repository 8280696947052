<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        Details
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="whitelist($event)">
            <ng-container *ngFor="let white of Whitelisttabs;let index=index">
                <mat-tab label="{{white.name}}" (click)="whitelist(white.name)">
                    <a class="btn btn-info m-2" data-toggle="modal" data-target="#usermodal" title="Create User"
                    (click)="createusermodal(white)"><span>Add {{white.name}} Whitelist</span></a></mat-tab>
            </ng-container>
          </mat-tab-group>
          <hr>
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr class="heading">
                        <th>Sr.No</th>
                        <th>Domain Name</th>
                        <th>IP</th>
                        <th>Expiry Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let user of userinfo;let index=index">
                        <td>{{index+1}}
                        </td>

                        <td>{{user.domain}}&nbsp;
                            <i class="fa fa-user fa_custom" title="Msg Locked" aria-hidden="true" style="color:red;"
                                *ngIf="user.status==0"></i>
                        </td>
                        <td style="padding-bottom: 0px;"><span class="m-bg">
                                <a>{{user.ip}}</a>
                            </span>
                        </td>
                        <td>{{dateformat(user.expiryDate)}}</td>
                        <td>
                            <a class="btn btn-info btn-xs" title="Edit User" data-toggle="modal"
                                data-target="#usermodal" (click)="updateuserdata(user)"><i class="fa fa-edit fa-4"
                                    aria-hidden="true"></i>
                            </a>
                            <a class="btn btn-danger btn-xs" title="Delete User" data-toggle="modal"
                                (click)="deletemodal(user)" data-target="#Confirmmodal"><span><i
                                        class="fa fa-trash" aria-hidden="true"></i></span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Userinfo -->
        <div id="usermodal" class="modal fade" role="dialog" aria-labelledby="usermodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>User Details</span><span>{{selectedsport?.name}}</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row" *ngIf="UserForm">
                            <form [formGroup]="UserForm" (ngSubmit)="adduser()">
                                <div>
                                    <label> Domain : </label>
                                    <input type="text" class="form-control" formControlName="domain">
                                </div>
                                <div>
                                    <label> IP : </label>
                                    <input type="text" class="form-control" formControlName="ip">
                                </div>
                                <div>
                                    <label> Expiry To : </label>
                                    <input type="text" class="form-control" formControlName="expiryDate">
                                </div>
                                <div class="popup_col_12 button-row">
                                    <button mat-raised-button color="primary" [disabled]="UserForm.invalid"
                                        type="submit" *ngIf="!isedit">Add</button>
                                    <button mat-raised-button color="primary" [disabled]="UserForm.invalid"
                                        type="submit" *ngIf="isedit">Update</button>
                                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                                        type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Confirm Info -->
        <div id="Confirmmodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to delete this record ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button"
                                (click)="deletewhitelist()">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </mat-card-content>
</mat-card>
