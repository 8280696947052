import { Component, OnInit } from '@angular/core';
import { AllapiService } from '../Services/allapi.service';
import { NotificationService } from '../Services/notification.service';

@Component({
  selector: 'app-lasermaintenance',
  templateUrl: './lasermaintenance.component.html',
  styleUrls: ['./lasermaintenance.component.scss']
})
export class LasermaintenanceComponent implements OnInit {
  maintainnance: any;
  Allapidata: any;
  devAdminIp: any;
  devIp: any;
  devAdminIp1: any;
  devIp1: any;
  sportApi: any;
  sportSocketApi: any;
  sslSportApi: any;
  sslsportSocketApi: any;
  sslSportApi2: any;
  sslsportSocketApi2: any;
  sslSportApi3: any;
  sslsportSocketApi3: any;
  sslSportApi4: any;
  sslsportSocketApi4: any;
  listGameApi: any;

  constructor(private Allapi: AllapiService,private notifyService: NotificationService,) { }

  ngOnInit(): void {
    this.GetAllapi();
  }
  Addapi(e: any) {
    this.Allapidata.maintanance = e;
    console.log(this.Allapidata.maintanance)
    this.StoreApi();
  }

  StoreApi() {
  
    this.Allapi.storemaintenance(this.Allapidata).subscribe((resp) => {

      if (resp.result == "Data saved!") {
        this.notifyService.success(resp.result);
        this.GetAllapi();
        $("[data-dismiss=modal]").trigger("click");
      } else {
        this.notifyService.error(resp.result);
      }
    });

  }
  GetAllapi() {
    this.Allapi.getAllmaintenance().subscribe((resp) => {
      this.Allapidata = resp;
      // this.Allapidata = JSON.parse(resp);
       console.log(this.Allapidata)
      

    })
  }


}
