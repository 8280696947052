import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { EventsService } from '../Services/events.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../Services/notification.service';
import { SessionUpdService } from '../session-upd/session-upd.service';

@Component({
  selector: 'app-fancy-type',
  templateUrl: './fancy-type.component.html',
  styleUrls: ['./fancy-type.component.scss']
})
export class FancyTypeComponent implements OnInit,OnDestroy,AfterViewInit {

  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  selectedStatus: any ;
  runsForm: FormGroup
  runs: string = '';
  checktnsettle: boolean = false;
  gameid:any
  markets:any
  eventId: string = '';
  getusertype: any;

  constructor(private eventservice :EventsService,
    private formBuilder:FormBuilder,
  private notifyService:NotificationService,
  private allapi: SessionUpdService) { }

  ngOnInit(): void {
    this.initDatatable();
    this.getusertype= localStorage.getItem('usertype');
    this.runsForm = this.formBuilder.group({
      runs: ['', Validators.required], 
    });
  }
  ngAfterViewInit() {

    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
  initDatatable() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      responsive: true,
      deferRender: true,
    };
  }

  filterSport(e: Event): void {
    const sportId = (<HTMLSelectElement>e.target).value;
    this.selectedStatus = sportId;     
  }
  validateEventId(eventId: string): boolean {
    return eventId && !/\s/.test(eventId); 
  }

  onSubmit() {
    if (!this.validateEventId(this.eventId)) {
      alert('Event ID should not contain spaces and should not be empty');
      return;
    }

    const data = {
      eventid: this.eventId,
      status: this.selectedStatus
    };
     this.eventservice.Fancytype(data).subscribe((resp:any)=>{
        this.markets = resp.data
        this.rerender();
      })


  }

  openreversesettlemodal(g: any) {
    this.gameid = g
    console.log(this.gameid);
    
  }
  
  reverseSetteledFancy() {
    if(this.gameid==null){
      this.notifyService.error("No bets found on vrnl");
    }
    else{
      if (this.checktnsettle) {
        return
      }
      this.checktnsettle = true;
      if (this.runsForm.valid) {
        const runsValue = this.runsForm.get('runs').value;
        this.allapi.reverseSetteledFancy(this.gameid,runsValue).subscribe((data :any) => {
         if(data?.errorCode == 0){
      
          // $("[data-dismiss=modal]").trigger("click");
          this.notifyService.success("Success");
         }
         else
         {
          this.notifyService.error("Error");
         }
         $("[data-dismiss=modal]").trigger("click");
         setTimeout(()=>{
          this.checktnsettle = false;
        },1000)
      }, err => {
        this.checktnsettle = false;
        console.log(err);
      });
      }
    }
  
  }

  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}
