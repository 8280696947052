import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OlgaserviceService {
  APIURL: any;

  constructor(private http: HttpClient) { }

  getRoundresult(roundId): Observable<any> {
    return this.http.get(`https://api.laserbook247.com:3440/api/round_result/${roundId}`);
  }

  storeOlgaresult(data): Observable<any> {
    return this.http.post(`${environment.CASINOURL}`, data);
  }
  storeSMBresult(roundId): Observable<any> {
    return this.http.get(`${environment.SMBCASINOURL}/api/set_round_result/${roundId}`);
  }
}
