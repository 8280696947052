import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from '../Services/login.service';
import { TokenService } from '../Services/token.service';
import { NotificationService } from '../Services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean=false;
  ipInfo: any;
  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private tokenService: TokenService,
    private notifyService: NotificationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initLoginForms();
  }

  initLoginForms() {
    this.loginForm = this.fb.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  

  get f() {
    return this.loginForm.controls;
  }

  // GetIpAddrress() {
  //   this.loginService.GetIpAddrress().subscribe(resp => {
  //     this.ipInfo = resp;
  //     this.loginForm.controls['ipAddress'].setValue(this.ipInfo.ip);
  //   })
  // }

  login() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loginService.Login(this.loginForm.value).subscribe((resp) => {
      if (resp.status == "Success") {
        this.notifyService.success(resp.status);
        this.tokenService.setToken(resp.token);
        this.tokenService.setUsername(this.loginForm.value.username);
        this.tokenService.setuserdata(JSON.stringify(resp));
        this.loginForm.reset();
        localStorage.setItem('usertype',resp.usertype)
        if(resp.usertype==2){
          this.router.navigateByUrl("whitelist");
        }else if(resp.usertype==3){
          this.router.navigateByUrl("fancysettle");
        }else if(resp.usertype==4){
          this.router.navigateByUrl("scoresetup");
        }else{
          this.router.navigateByUrl("home");
        }
      } else {
        this.notifyService.error(resp.data);
      }
    });
  }

}
