import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject,interval, Subscription } from 'rxjs';
import { AllapiService } from '../Services/allapi.service';
import { NotificationService } from '../Services/notification.service';
import { TokenService } from '../Services/token.service';
import { AmanfancyService } from './amanfancy.service';

@Component({
  selector: 'app-amanfancy',
  templateUrl: './amanfancy.component.html',
  styleUrls: ['./amanfancy.component.scss']
})
export class AmanfancyComponent implements OnInit,OnDestroy,AfterViewInit {

  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  AddFancyForm: FormGroup;
  fancyList: any=[];
  allimportFancy: any;
  importfancyinterval: Subscription;
  checknewfancy: Subscription;
  Allimportedfancies: any = [];
  options = [
  {
    status: 0,
    type:"Active"
  },
  {
    status: 1,
    type:"Settled"
  },
  {
    status: 2,
    type:"Cancelled"
  }];
  selectedStatus: any = "0";
  loader = false;
  selectevent ="";
  fancydata: any;
  AllfancyList: any;
  Oldimportedfancy: any=[];
  Settlesession: any;
  highlights: any;
  Search: any;
  selectfromdate: Date;
  
  constructor(private fb: FormBuilder, private allapi: AmanfancyService, private notifyService: NotificationService,private tokenService: TokenService) {
    $(document).on("focus", 'input[type="search"]', function () {
      var ele:any = $('input[type="search"]').val();
      var table = $("#fancytable").DataTable();
      table.search(ele, false, false, false).draw();
    });
  }
  

  ngOnInit(): void {
    // this.selectfromdate = new Date(new Date());
    // this.selectfromdate = new Date(new Date().setHours(0, 0, 0));
    
    this.initUserForm();
    this.getFancyList();
    this.getHighlights();
    this.importfancyinterval = interval(25000).subscribe((x => {
      this.getHighlights();
    }));
    this.initDatatable();
  }

  initUserForm() {
    this.AddFancyForm = this.fb.group({
      fancyname: ['', Validators.required],
      date: ['', Validators.required],
      eventname: ['', Validators.required],
      eventid: ['', Validators.required],
      result: [null],
      compname: [null],
      status:[0]
    });
  }

  initDatatable() {
    // console.log('table')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      processing: true,
      responsive: true,
      deferRender: true,
      order:[[0, 'desc']]
    };
  }

  getFancyList() {
    this.loader = true;
    this.allapi.GetAmanfancyList(this.selectedStatus,this.selectevent,3).subscribe(resp => {
      this.fancyList = resp.data;
      this.loader = false;
      this.rerender();
    })
  }
  onchangeinput(d) {
    if (d != null) {
      var event = [`${d.getFullYear()}`, `0${d.getMonth()+1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-");
    } else {
      event = "";
    }
    // console.log(d, [`${d.getFullYear()}`, `0${d.getMonth()+1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-"));
      var table = $("#fancytable").DataTable();
      table.search(event, false, false, false).draw();
  }

  resetForm() {
      this.initUserForm();
  }

  getHighlights() {
    this.allapi.GetAmanAllevents().subscribe(resp => {
      this.highlights = resp.data;
    })
  }

  addSessions() {
    var data = {
      "Fancy": this.AddFancyForm.value.fancyname,
      "Status": 0,
      "Date": this.AddFancyForm.value.date,
      "Eventname": this.AddFancyForm.value.eventname,
      "Eventid": this.AddFancyForm.value.eventid,
      "Result": null,
      "CompititionName": null,
      "type":3
    }
    console.log(data)
    this.allapi.AddAmanFancy2(data).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        // $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  updateUnverified(fancy){
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: fancy.Result,
      fsource:1
    }
    //console.log(fancy,resultData);
    this.storefancyresult(resultData);
  }

  Updateresult(fancy) {
    if (fancy.Result == null) {
      this.notifyService.error("Please Enter Result");
      return false;
    }
    var data = {
      "Id":fancy.Id,
      "Status": 1,
      "Result": fancy.Result,
      "Settledby": this.tokenService.getUsername(),
      "Reversed":null
    }
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: fancy.Result,
      fsource:1
    }
    this.storefancyresult(resultData);
    this.allapi.UpdateAmanFancyResult(data).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  updatestatus(fancy) {
    var data = {
      "Id":fancy.Id,
      "Status": fancy.Status
    }
    this.allapi.updateAmanFancyStatus(data).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        // $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  fancyinfodata(data) {
    this.fancydata = data;
    this.Settlesession = data.Result;
    //console.log(this.fancydata);
  }

  cancelfancy(fancy) {
    var data = {
      "Id":fancy.Id,
      "Status": 2,
      "Result": -1,
      "Settledby": this.tokenService.getUsername(),
      "Reversed":null
    }
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: -1,
      fsource:1
    }
    this.storefancyresult(resultData);
    this.allapi.UpdateAmanFancyResult(data).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  reversefancy(fancy) {
    var data = {
      "Id":fancy.Id,
      "Status": 0,
      "Result": null,
      "Settledby": this.tokenService.getUsername(),
      "Reversed":this.tokenService.getUsername()
    }
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: null,
      fsource:1
    }
    this.storefancyresult(resultData);
    this.allapi.UpdateAmanFancyResult(data).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  storefancyresult(fancy) {
    this.allapi.Addfancyresult(fancy).subscribe(data => {
      console.log(data)
      this.notifyService.success(data.result);
    })
  }

  verifyfancyresult(fancy) {
    if (fancy.CompitionName == null) {
      this.allapi.Verifyfancyresult(fancy.Eventid,fancy.Fancy,1).subscribe(resp => {
        // console.log(resp)
        fancy.CompitionName = resp.result;
        if (resp.result == null) {
          this.notifyService.success("Not Yet Settled");
        }
      })
    } else {
      fancy.CompitionName = null;
    }
    
  }

  trackfancyid(index,fancy)
  {
    return fancy.Id;
  }
  trckbyeventid(index,high)
  {
    return high.gameId;
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    this.importfancyinterval.unsubscribe();
    // this.checknewfancy.unsubscribe();
  }

  rerender() {
    this.selectfromdate = null;
    this.onchangeinput(null)
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}
