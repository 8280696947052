import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ISettleMarket } from './types/settle-market';
import { IBMMarket } from './types/bm-market';
import { IVirtualCricket } from './types/virtual-cricket';
import { SettlementService } from './settlement.service';
import { DatePipe } from '@angular/common';
import { GenericResponse } from './types/generic-response';
import { delay, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../Services/notification.service';

@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.scss']
})
export class SettlementComponent implements OnInit {
  matchDate;
  matchTime;
  dateConfig;
  timeConfig;

  sportsEventIds = [
    { name: 'Soccer', id: '1' },
    { name: 'Tennis', id: '2' },
    { name: 'Cricket', id: '4' },
    { name: 'Teenpatti/ X-Games', id: 'casino' },
    { name: 'Horse Racing', id: '7' },
    { name: 'Greyhound Racing', id: '4339' },
    { name: 'Virtual Games', id: '-1012' },
    { name: 'TNPL BM', id: '1040' },
    { name: 'Kabbaddi', id: '52' },
  ];
  selectedSport: { name: string; id: string } = this.sportsEventIds[0];

  marketList: ISettleMarket[];
  selectedMarket: ISettleMarket;

  getListCalls = new Subject();
  virtualGames: IVirtualCricket[];

  panelsStatusMap = {};
  isConfirmVoidOpen: boolean = false;
  bmMarkets: IBMMarket[];
  filteredBmMarkets :any
  Kabaddigames = [];
  month;
  checktnplbm: boolean = false;
  checkbook: boolean = false;
  checkfancy: boolean = false;
  selectfromdate: Date;
  selecttodate: Date
  bmData:any
  selectedSportForFilter: string = '0';
  constructor(
    private settlementService: SettlementService,
    private datePipe: DatePipe,
    private notifyService: NotificationService,
  ) {
    this.month = datePipe.transform(new Date().setDate(new Date().getDate() - 30), 'yyyy-MM-dd');
    this.dateConfig = {
      format: 'YYYY-MM-DD',
      min: this.month,
    };
    this.timeConfig = {
      hours24Format: 'hh',
      showSeconds: true,
    };

    this.matchDate = new Date();
    this.matchTime = datePipe.transform(
      new Date().setHours(0, 0, 0),
      'HH:mm:ss'
    );
  }

  ngOnInit(): void { }

  getGames() {
    let dates = {
      "formdate": this.getFromDateAndTime(),
    }
    if (+this.selectedSport.id == -1012) {
      this.getVirtualGames();
    } else if (+this.selectedSport.id == 1040) {
      this.getTNPLBmMarkets();
    } else if (+this.selectedSport.id == 52) {
      this.getkabaddimarkets();
    } else {
      this.getListCalls.next();
      this.settlementService
        .closedGames(dates.formdate, this.selectedSport.id)
        .pipe(delay(1500), takeUntil(this.getListCalls))
        .subscribe((res: GenericResponse<any>) => {
          //  console.log(res);
          if (res.errorCode == 0) {
            this.resetMarketList();
            this.marketList = res.result;
            this.notifyService.success('Success');
          } else {
            this.notifyService.error('Something went wrong');
          }
        });
    }
  }

  getVirtualGames() {
    let dates = {
      "formdate": this.getFromDateAndTime(),
    }
    this.settlementService
      .getVirtual(dates.formdate)
      .subscribe((res: GenericResponse<IVirtualCricket[]>) => {
        //  console.log(res);
        this.resetMarketList();
        this.virtualGames = res.result;
      });
  }

  getkabaddimarkets() {
    let dates = {
      "formdate": this.getFromDateAndTime(),
    }
    this.settlementService
      .getkabaddiGames(dates.formdate)
      .subscribe((res: GenericResponse<IVirtualCricket[]>) => {
        //  console.log(res);
        this.resetMarketList();
        this.Kabaddigames = res.result;
      });
  }

  settleMarket() {
    this.settlementService
      .setResult(this.selectedMarket.sportId, this.selectedMarket.marketId)
      .subscribe((res: GenericResponse<any>) => {
        //  console.log(res);
        if (res.errorCode == 0) {
          this.notifyService.success('Market Settled');
        } else {
          this.notifyService.error('Something went wrong');
        }
      });
  }

  getTNPLBmMarkets() {
    let dates = {
      "formdate": this.getFromDateAndTime(),
    }
    this.settlementService
      .getBmMarkets(dates.formdate)
      .subscribe((res: GenericResponse<IBMMarket[]>) => {
        //  console.log(res);
        this.resetMarketList();
        this.bmMarkets = res.result;
        // this.bmMarkets = res.result.filter(market => 
        //   market.marketId.length >= 16 || market.sportName.toLowerCase() !== "cricket");
        this.bmMarkets.sort(function (a, b) {
          //Ascending Order
          // return <any>new Date(a.startTime)-<any>new Date(b.startTime);
          //Descending Order
          return <any>new Date(b.startTime) - <any>new Date(a.startTime);
        });
        this.filteredBmMarkets = this.bmMarkets;
        this.filterBmMarkets()
        // console.log(this.bmMarkets)
      });
  }

  get24HrsDate(startTime: string): string {
    const [date, time, a] = startTime.split(' ');
  
    let time12h = time + ' ' + a;
  
    // Convert the date and time to the correct format
    startTime = this.convertDate(date) + 'T' + this.convertTime12to24(time12h);
  
    // Return the correctly formatted date-time string
    return startTime;
  }
  
  convertDate(date: string): string {
    let [mm, dd, yyyy] = date.split('/');
  
    // Ensure month and day are two digits
    mm = mm.padStart(2, '0');
    dd = dd.padStart(2, '0');
  
    return `${yyyy}-${mm}-${dd}`;
  }
  
  convertTime12to24(time12h: string): string {
    let [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12).toString().padStart(2, '0');
    } else {
      hours = hours.padStart(2, '0');
    }
  
    return `${hours}:${minutes}:00Z`;
  }
  

  confirmVoid() {
    this.isConfirmVoidOpen = true;
  }

  voidMarket() {
    this.settlementService
      .voidBets(this.selectedMarket.marketId)
      .subscribe((res: GenericResponse<any>) => {
        //  console.log(res);
        if (res.errorCode == 0) {
          this.notifyService.success('Market Bets Voided');
        } else {
          this.notifyService.error('Something went wrong');
        }
      });
  }

  selectSport(sport) {
    this.selectedSport = sport;
  }

  selectMarket(market) {
    this.selectedMarket = market;
  }

  settleBook(marketId, selectionId) {
    if (this.checkbook) {
      return
    }
    this.checkbook = true;
    //  console.log(marketId, selectionId);
    this.settlementService
      .settleVirtual('bookmaker', marketId, selectionId)
      .subscribe((res: GenericResponse<any>) => {
        //  console.log(res);
        if (res.errorCode == 0) {
          this.notifyService.success(res.errorDescription || 'Settled Bookmaker');
          this.getVirtualGames();
        } else {
          this.notifyService.error(res.errorDescription || 'Something went wrong');
        }
      }, err => {
        this.checkbook = false;
        console.log(err);
      });
  }

  parsedRunners: any[] = [];
  resultName: string = '';

  openSettleModal(event: any, selectionId: any) {
    this.bmData = {
      "eventName": event.eventName,
      "marketId": event.marketId,
      "eventId": event.eventId,
      "result": selectionId,
      "gameId": event.gameId,
      "runners": JSON.stringify(event.selections)
    };
    this.parsedRunners = JSON.parse(this.bmData.runners);

    if (selectionId === '-1') {
      this.resultName = 'Void market';
    } else if (selectionId === '0') {
      this.resultName = 'Selection';
    } else {
      const selectedRunner = this.parsedRunners.find(runner => runner.selId === selectionId);
      this.resultName = selectedRunner ? `${selectedRunner.name} (ID: ${selectionId})` : '';
    }  }
  settleTNPLBm(){    
    this.settlementService.settleBmMarket(this.bmData).subscribe(data => {
      if (data.status === "Success") {
        setTimeout(() => {
          this.getTNPLBmMarkets();
        }, 1000);
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.message);
      } else {
        this.notifyService.error(data.message);
      }
    });
  
  }
  // settleTNPLBm(event, selectionId) {
  //   if (this.checktnplbm) {
  //     return
  //   }
  //   this.checktnplbm = true;
  //   console.log(event);
  //   let bmData = {
  //       "eventName":event.eventName,
  //       "marketId":event.marketId,
  //       "eventId":event.eventId,
  //       "result":selectionId,
  //       "gameId":event.gameId,
  //       "runners":JSON.stringify(event.selections)
  //   }    
  //   // this.settlementService
  //   //   .settleTNPLBmMarket(marketId, selectionId)
  //   //   .subscribe((res: GenericResponse<any>) => {
  //   //     //  console.log(res);
  //   //     if (res.errorCode == 0) {
  //   //       this.notifyService.success(res.errorDescription || 'Settled Bookmaker');
  //   //       setTimeout(()=>{
  //   //         this.getTNPLBmMarkets();
  //   //       },1000)
  //   //     } else {
  //   //       this.notifyService.error(res.errorDescription || 'Something went wrong');
  //   //     }
  //   //     setTimeout(()=>{
  //   //       this.checktnplbm = false;
  //   //     },1000)
  //   //   }, err => {
  //   //     this.checktnplbm = false;
  //   //     console.log(err);
  //   //   });

  //   this.settlementService.settleBmMarket(bmData).subscribe(data => {
  //     if (data.status == "Success") {
  //       setTimeout(() => {
  //         this.getTNPLBmMarkets();
  //       }, 1000)
  //       this.notifyService.success(data.message);
  //       this.checktnplbm = false;
  //     } else {
  //       this.checktnplbm = false;
  //       this.notifyService.error(data.message);
  //     }
  //   })
  // }

  settlekabaddi(marketId, selectionId) {
    this.settlementService
      .settleKabaddiGames('bookmaker', marketId, selectionId)
      .subscribe((res: GenericResponse<any>) => {
        //  console.log(res);
        if (res.errorCode == 0) {
          this.notifyService.success(res.errorDescription || 'Settled Kabaddi Bookmaker');
          this.getkabaddimarkets();
        } else {
          this.notifyService.error(res.errorDescription || 'Something went wrong');
        }
      });
  }

  settleFancy(marketId, runs) {
    if (this.checkfancy) {
      return
    }
    this.checkfancy = true;
    //  console.log(marketId, runs);
    this.settlementService
      .settleVirtual('fancy', marketId, runs)
      .subscribe((res: GenericResponse<any>) => {
        //  console.log(res);
        if (res.errorCode == 0) {
          this.notifyService.success(res.errorDescription || 'Settled Fancy');
          this.getVirtualGames();
        } else {
          this.notifyService.error(res.errorDescription || 'Something went wrong');
        }
      }, err => {
        this.checkfancy = false;
        console.log(err);
      });
  }
  getFromDateAndTime() {
    return `${this.matchDate.getFullYear()}-${this.matchDate.getMonth() + 1}-${this.matchDate.getDate()}`;

  }
  resetMarketList() {
    this.marketList = [];
    this.virtualGames = [];
    this.bmMarkets = [];
  }

  splitSelectionNames(eventName: string) {
    eventName = eventName.replace(' V ', ' v ');
    if (eventName.split(' v ').length < 2) {
      return eventName.split(' vs ');
    } else {
      return eventName.split(' v ');
    }
  }

  filterSport(e: Event): void {
    const sportId = (<HTMLSelectElement>e.target).value;
    this.selectedSportForFilter = sportId; // Use the new variable for filtering
    this.filterBmMarkets(); // Apply the filter whenever the sport is changed
  }

  filterBmMarkets(): void {
    if (this.selectedSportForFilter === '0') {
      this.filteredBmMarkets = this.bmMarkets;
    } else {
      const sportNameMap: { [key: string]: string } = {
        '1': 'soccer',
        '2': 'tennis',
        '4': 'cricket',
        '52': 'kabaddi'
      };

      const sportName = sportNameMap[this.selectedSportForFilter];

      this.filteredBmMarkets = this.bmMarkets.filter(market => market.sportName.toLowerCase() === sportName.toLowerCase());
    }
  }

  closeVoidModal() {
    this.isConfirmVoidOpen = false;
  }

  trackByMarketId(index, item) {
    return item.marketId;
  }

  trackByEventId(index, item) {
    return item.eventId;
  }

  trackByGameId(index, item) {
    return item.gameId;
  }
}
