import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DreamserviceService {

  BASEURL = environment.BASEURL;
  constructor(private http: HttpClient) { }

  list(url): Observable<any> {
    return this.http.get(`http://194.163.181.206:33330/?list_${url}=all`);
  }
  whitelist(url,data): Observable<any> {
    return this.http.get(`http://194.163.181.206:33330/?whitelist_${url}=${data}`);
  }
  updatewhitelist(url,data): Observable<any> {
    return this.http.get(`http://194.163.181.206:33330/?${url}`);
  }
  deletewhitelist(url,data): Observable<any> {
    return this.http.get(`http://194.163.181.206:33330/?delete_${url}=${data}`);
  }
}
