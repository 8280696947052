import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WhitelistserviceService {
  BASEURL = environment.BASEURL;
  constructor(private http: HttpClient) { }

  list(url): Observable<any> {
    return this.http.get(`http://13.202.98.222:99/?list_${url}=all`);
  }
  whitelist(url,data): Observable<any> {
    return this.http.get(`http://13.202.98.222:99/?whitelist_${url}=${data}`);
  }
  updatewhitelist(url,data): Observable<any> {
    return this.http.get(`http://13.202.98.222:99/?${url}`);
  }
  deletewhitelist(url,data): Observable<any> {
    return this.http.get(`http://13.202.98.222:99/?delete_${url}=${data}`);
  }
  DeleteSingleuser(id): Observable<any> {
    return this.http.delete(`${this.BASEURL}/userdetail/delete?id=${id}`);
  }
}
