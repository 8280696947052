import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject,interval, Subscription } from 'rxjs';
import { AllapiService } from '../Services/allapi.service';
import { NotificationService } from '../Services/notification.service';
import { TokenService } from '../Services/token.service';
import { TkfancyService } from './tkfancy.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-tkfancy',
  templateUrl: './tkfancy.component.html',
  styleUrls: ['./tkfancy.component.scss']
})
export class TKfancyComponent implements OnInit,OnDestroy,AfterViewInit {
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  AddFancyForm: FormGroup;
  fancyList: any=[];
  allimportFancy: any;
  importfancyinterval: Subscription;
  checknewfancy: Subscription;
  Allimportedfancies: any = [];
  options = [
  {
    status: 0,
    type:"Active"
  },
  {
    status: 1,
    type:"Settled"
  },
  {
    status: 2,
    type:"Cancelled"
  }];
  selectedStatus: any = "0";
  loader = false;
  selectevent :any="";
  fancydata: any;
  AllfancyList: any;
  Oldimportedfancy: any=[];
  Settlesession: any;
  highlights: any;
  Search: any;
  selectfromdate: Date;
  selectedUid: any;
  paramselectedevnet: any;
  gameid: any;
  runs: string = '';
  runsForm: FormGroup;
  checktnsettle: boolean = false;
  getusertype: any;
  isAllSelected: boolean = false;
  multiID:any;
  selectedItems: any[] = [];


  constructor(private fb: FormBuilder, private route: ActivatedRoute,private formBuilder: FormBuilder, private allapi: TkfancyService, private notifyService: NotificationService,private tokenService: TokenService) {
    $(document).on("focus", 'input[type="search"]', function () {
      var ele:any = $('input[type="search"]').val();
      var table = $("#fancytable").DataTable();
      table.search(ele, false, false, false).draw();
    });
    this.getusertype= localStorage.getItem('usertype');
    this.route.params.subscribe((params) => {
      this.selectedUid = params.selectedUid
      console.log(this.selectedUid)
      if (this.selectedUid) {
       this.getEventlist();
        this.selectevent = + params.selectedUid
      } else {
        this.selectevent = "";
      }
    })
    this.runsForm = this.formBuilder.group({
      runs: ['', Validators.required], 
    });
  }
  

  ngOnInit(): void {
    // this.selectfromdate = new Date(new Date());
    // this.selectfromdate = new Date(new Date().setHours(0, 0, 0));
    
    this.initUserForm();
    this.getFancyList();
    this.getEventlist();
    this.importfancyinterval = interval(25000).subscribe((x => {
      this.getEventlist();
    }));
    this.initDatatable();
  }

  initUserForm() {
    this.AddFancyForm = this.fb.group({
      fancyname: ['', Validators.required],
      date: ['', Validators.required],
      eventname: ['', Validators.required],
      eventid: ['', Validators.required],
      result: [null],
      compname: [null],
      status:[0]
    });
  }

  initDatatable() {
    // console.log('table')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      processing: true,
      responsive: true,
      deferRender: true,
      order:[[0, 'desc']]
    };
  }

  getFancyList() {
    this.selectedItems = []; 
    this.isAllSelected =false;
    this.loader = true;
    // console.log("selectevent",this.selectevent);
    if (this.selectedStatus != null && this.selectevent != "") {
    this.allapi.GetTKfancyList(this.selectedStatus,this.selectevent,2).subscribe(resp => {
      this.fancyList = resp.data;
      this.loader = false;
      this.rerender();
    })
  }
  }
  onchangeinput(d) {
    if (d != null) {
      var event = [`${d.getFullYear()}`, `0${d.getMonth()+1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-");
    } else {
      event = "";
    }
    // console.log(d, [`${d.getFullYear()}`, `0${d.getMonth()+1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-"));
      var table = $("#fancytable").DataTable();
      table.search(event, false, false, false).draw();
  }

  resetForm() {
      this.initUserForm();
  }
  openreversesettlemodal(g: any) {
    this.gameid = g
  }
  reverseSetteledFancy() {
    if(this.gameid==null){
      this.notifyService.error("No bets found on vrnl");
    }
    else{
      if (this.checktnsettle) {
        return
      }
      this.checktnsettle = true;
      if (this.runsForm.valid) {
        const runsValue = this.runsForm.get('runs').value;
        this.allapi.reverseSetteledFancy(this.gameid,runsValue).subscribe((data :any) => {
         console.log(data)
         if(data?.errorCode == 0){
          this.getFancyList();
          // $("[data-dismiss=modal]").trigger("click");
          this.notifyService.success("Success");
         }
         else{
          this.notifyService.error("Error");
         }
         $("[data-dismiss=modal]").trigger("click");
         setTimeout(()=>{
          this.checktnsettle = false;
        },1000)
      }, err => {
        this.checktnsettle = false;
        console.log(err);
      });
     }
    }


  }
  getEventlist() {
    this.allapi.GetTKAllevents().subscribe(resp => {
      this.highlights = resp.data;
      // this.highlights.forEach(high => {
      //   if (high.eventid == this.selectedUid) {
      //     this.paramselectedevnet= high.eventname
      //     this.selectevent = this.paramselectedevnet;
      //     console.log('Selected event found:', high.eventname);

      //   }else{
      //     this.paramselectedevnet
      //   }
      // })

      
    })
  }

  toggleSelection(item: any, isChecked: boolean) {
    const index = this.selectedItems.findIndex(selectedItem => selectedItem.Id === item.Id);
    if (isChecked && index === -1) {
      this.selectedItems.push(item);
    } else if (!isChecked && index !== -1) {
      this.selectedItems.splice(index, 1);
    }
    this.isAllSelected = this.fancyList.length === this.selectedItems.length;
    this.multiID = this.selectedItems.map(selectedItem => selectedItem.Id).join(', ');
    console.log("Selected IDs:", this.multiID);
}
  
  isSelected(item: any): boolean {
    return this.selectedItems.includes(item);
  }
  selectAll(isChecked: boolean) {
    this.selectedItems = isChecked ? [...this.fancyList] : [];
    this.isAllSelected = isChecked;
    this.multiID = this.selectedItems.map(item => item.Id).join(', ')
    console.log("Selected IDs:",this.multiID);
    // console.log("Selected IDs:", this.selectedItems.map(item => item.sbmarketid).join(', '));
  }


  addSessions() {
    var data = {
      "Fancy": this.AddFancyForm.value.fancyname,
      "Status": 0,
      "Date": this.AddFancyForm.value.date,
      "Eventname": this.AddFancyForm.value.eventname,
      "Eventid": this.AddFancyForm.value.eventid,
      "Result": null,
      "CompititionName": null,
      "type":1
    }
    // console.log(data)
    this.allapi.AddTKFancy(data).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        // $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  updateUnverified(fancy){
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: fancy.Result,
      fsource:1
    }
    //console.log(fancy,resultData);
    
    this.storefancyresult(resultData);
  }

  vrnlfancylive(gameId,result){
    this.allapi.VrnlFancyLive(gameId,result).subscribe((res) =>{
      // console.log(res);
    })
  }

  Updateresult(fancy) {
    if (fancy.Result == null) {
      this.notifyService.error("Please Enter Result");
      return false;
    }
    var data = {
      "Id":fancy.Id,
      "Status": 1,
      "Result": fancy.Result,
      "Settledby": this.tokenService.getUsername(),
      "Reversed":null
    }
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: fancy.Result,
      fsource:1
    }
    this.storefancyresult(resultData);
    this.vrnlfancylive(fancy.gameId,fancy.Result)
    this.allapi.UpdateTKFancyResult(data).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  updatestatus(fancy) {
    var data = {
      "Id":fancy.Id,
      "Status": fancy.Status
    }
    this.allapi.updateTKFancyStatus(data).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        // $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  fancyinfodata(data) {
    this.fancydata = data;
    this.Settlesession = data.Result;
    // console.log(this.fancydata);
  }
  cancelmultifancy(){
    var data = {
      "fIds": this.multiID,
      "userId": this.tokenService.getuid(),
      "source":1
    }

    this.allapi.SettleMultiSportBook(data).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success("Market Canceled Successfully...");
      } else {
        this.notifyService.error(data.message);
      }
    })
  }

  cancelfancy(fancy) {
    var data = {
      "Id":fancy.Id,
      "Status": 2,
      "Result": -1,
      "Settledby": this.tokenService.getUsername(),
      "Reversed":null
    }
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: -1,
      fsource:1
    }
    this.storefancyresult(resultData);
    this.allapi.UpdateTKFancyResult(data).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  reversefancy(fancy) {
    var data = {
      "Id":fancy.Id,
      "Status": 0,
      "Result": null,
      "Settledby": this.tokenService.getUsername(),
      "Reversed":this.tokenService.getUsername()
    }
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: null,
      fsource:1
    }
    this.storefancyresult(resultData);
    this.allapi.UpdateTKFancyResult(data).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  storefancyresult(fancy) {
    this.allapi.Addfancyresult(fancy).subscribe(data => {
      // console.log(data)
      this.notifyService.success(data.result);
    })
  }

  verifyfancyresult(fancy) {
    if (fancy.CompitionName == null) {
      this.allapi.Verifyfancyresult(fancy.Eventid,fancy.Fancy,1).subscribe(resp => {
        // console.log(resp)
        fancy.CompitionName = resp.result;
        if (resp.result == null) {
          this.notifyService.success("Not Yet Settled");
        }
      })
    } else {
      fancy.CompitionName = null;
    }
    
  }

  verifyfancyresult1(fancy) {
    // console.log("fancy1",fancy);
    
    if (fancy.CompitionName == null) {
      this.allapi.Verifyfancyresult(fancy.Eventid, fancy.Fancy, 1).subscribe(resp => {
        this.fancyList.forEach((ele)=>{
          if(ele.Eventid == fancy.Eventid && ele.Fancy == fancy.Fancy){
            ele.CompitionName = resp.result;
          }
        })
      })
    } else {
      fancy.CompitionName = null;
    }
    
  }

  verifyAllSkyFancyResult(eventid){
    this.allapi.verifyAllSkyFancyResult(eventid,this.selectedStatus).subscribe((res:any) =>{
      // console.log("verify",res);
      res.data.forEach(element => {
        this.verifyfancyresult1(element)
      });
      
    })
  }

  updateAllSkyFancyResult(eventid){
    this.allapi.updateAllSkyFancyResult(eventid,this.selectedStatus).subscribe( (resp:any) =>{
      // console.log("update",resp);
      if (resp) {
        this.notifyService.success(resp.message);
      }
      
    })
  }

  trackfancyid(index,fancy)
  {
    return fancy.Id;
  }
  trckbyeventid(index,high)
  {
    return high.gameId;
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    this.importfancyinterval.unsubscribe();
    // this.checknewfancy.unsubscribe();
  }

  rerender() {
    this.selectfromdate = null;
    this.onchangeinput(null)
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}

