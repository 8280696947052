import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { AllapiService } from '../Services/allapi.service';
import { TokenService } from '../Services/token.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() inputSideNav: MatSidenav;
  accountInfosrc: Subscription;
  accountInfo: any;
  params: any;
  userrole: any;
  getusertype: any;
  uid :any
  constructor(private route: ActivatedRoute,private allapi: AllapiService,private tokenService: TokenService) {
    this.getusertype= localStorage.getItem('usertype');
   }

  ngOnInit(): void {
    $(document).ready(function () {
      $('#MainMenu > li').click(function(e) {
          e.stopImmediatePropagation();
        var $el = $('ul', this);
        // console.log($el)
          $('#MainMenu > li > ul').not($el).slideUp();
          $el.stop(true, true).slideToggle(400);
      });
        $('#MainMenu > li > ul > li').click(function(e) {
            e.stopImmediatePropagation();  
        });
    })
    this.userinfo();
  }

  userinfo() {
    this.allapi.Getuserinfo(this.tokenService.getUsername()).subscribe(resp => {
      // console.log(resp)
      this.userrole = resp.userrole;
      this.uid = resp.uid;
      this.tokenService.setuid(this.uid);
    })
  }

  



}
