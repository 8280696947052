import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  baseUrl= 'https://222222.digital/pad=81'

  constructor(private httpClient: HttpClient) { }

  listGame(sportName?: string) {
    const url = sportName ? `${this.baseUrl}/listGames/${sportName}` : `${this.baseUrl}/listGames`;
    return this.httpClient.get(url);
  }
  activateListGame(sportName?: string) {
    const url = sportName ? `${this.baseUrl}/listActiveGames/${sportName}` : `${this.baseUrl}/listActiveGames/`;
    return this.httpClient.get(url);
  }

  getGame(selctedGid: number) {
    return this.httpClient.get(`${this.baseUrl}/listGames/${selctedGid}`);
  }

  activeGame(gameIds) {
    return this.httpClient.post(`${this.baseUrl}/activateGames`, gameIds);
  }

  excludeGames(gameIds) {
    return this.httpClient.post(`${this.baseUrl}/excludeGames`, gameIds);
  }

  ReverseInplayGames(gameIds) {
    return this.httpClient.post(`${this.baseUrl}/ReverseInplayGames`, gameIds);
  }

  inplayGames(gameIds: number[]) {
    return this.httpClient.post(`${this.baseUrl}/inplayGames`, gameIds);
  }

  activateFancy(fancyData,source) {
    return this.httpClient.post(`${this.baseUrl}/activateFancy/${source}`, fancyData);
  }

  deactivateFancy(fancyData,source) {
    return this.httpClient.post(`${this.baseUrl}/deactivateFancy/${source}`, fancyData);
  }

  activatedeactivatewinner(winnerData,eventId,source) {
    return this.httpClient.post(`${this.baseUrl}/premiumMain/${eventId}/${source}`, winnerData);
  }

  activatePremium(PremiumData) {
    return this.httpClient.post(`${this.baseUrl}/activatePremium`, PremiumData);
  }

  deactivatePremium(PremiumData) {
    return this.httpClient.post(`${this.baseUrl}/deactivatePremium`, PremiumData);
  }

  activateMO(matchoddData) {
    return this.httpClient.post(`${this.baseUrl}/activateMOMarket`, matchoddData);
  }
  activateMO1(matchoddData) {
    return this.httpClient.post(`${this.baseUrl}/activateMOMarket1`, matchoddData);
  }
  deactivateMO1(matchOddData) {
    return this.httpClient.post(`${this.baseUrl}/deactivateMOMarket1`, matchOddData);
  }
  deactivateMO(matchOddData) {
    return this.httpClient.post(`${this.baseUrl}/deactivateMOMarket`, matchOddData);
  }


  activateOU(OverUnderData) {
    return this.httpClient.post(`${this.baseUrl}/activateOUMarket`, OverUnderData);
  }

  deactivateOU(OverUnderData) {
    return this.httpClient.post(`${this.baseUrl}/deactivateOUMarket`, OverUnderData);
  }

  activateOU1(OverUnderData) {
    return this.httpClient.post(`${this.baseUrl}/activateOUMarket1`, OverUnderData);
  }

  deactivateOU1(OverUnderData) {
    return this.httpClient.post(`${this.baseUrl}/deactivateOUMarket1`, OverUnderData);
  }

  activateTM(TiedMarketData) {
    return this.httpClient.post(`${this.baseUrl}/activateTiedMarket`, TiedMarketData);
  }

  deactivateTM(TiedMarketData) {
    return this.httpClient.post(`${this.baseUrl}/deactivateTiedMarket`, TiedMarketData);
  }
  activateTM1(TiedMarketData) {
    return this.httpClient.post(`${this.baseUrl}/activateTiedMarket1`, TiedMarketData);
  }

  deactivateTM1(TiedMarketData) {
    return this.httpClient.post(`${this.baseUrl}/deactivateTiedMarket1`, TiedMarketData);
  }


  activateSOMarket(TiedMarketData) {
    return this.httpClient.post(`${this.baseUrl}/activateSOMarket`, TiedMarketData);
  }

  deactivateSOMarket(TiedMarketData) {
    return this.httpClient.post(`${this.baseUrl}/deactivateSOMarket`, TiedMarketData);
  }


  activateTossM(TiedMarketData) {
    return this.httpClient.post(`${this.baseUrl}/activateTossMarket`, TiedMarketData);
  }

  deactivateTossM(TiedMarketData) {
    return this.httpClient.post(`${this.baseUrl}/deactivateTossMarket`, TiedMarketData);
  }

  activateTossM1(TiedMarketData) {
    return this.httpClient.post(`${this.baseUrl}/activateTossMarket1`, TiedMarketData);
  }

  deactivateTossM1(TiedMarketData) {
    return this.httpClient.post(`${this.baseUrl}/deactivateTossMarket1`, TiedMarketData);
  }

  deactivateBM(bmData,source) {
    return this.httpClient.post(`${this.baseUrl}/deactivateBm//${source}`, bmData);
  }

  activateBM(bmData,source) {
    return this.httpClient.post(`${this.baseUrl}/activateBm/${source}`, bmData);
  }

  activateDraw(fancyData) {
    return this.httpClient.post(`${this.baseUrl}/activateDraw`, fancyData);
  }

  deactivateDraw(fancyData) {
    return this.httpClient.post(`${this.baseUrl}/deactivateDraw`, fancyData);
  }

  suspendBets(sportId, eventId) {
    return this.httpClient.get(
      `${this.baseUrl}/lockBets/${sportId}/${eventId}`
    );
  }

  acceptBets(sportId, eventId) {
    return this.httpClient.get(
      `${this.baseUrl}/unlockBets/${sportId}/${eventId}`
    );
  }

  listVirtualGames() {
    return this.httpClient.get(`${this.baseUrl}/listVirtualGames`);
  }

  stopFancy(eventId) {
    return this.httpClient.get(`${this.baseUrl}/stopFancy/${eventId}`);
  }

  startFancy(eventId) {
    return this.httpClient.get(`${this.baseUrl}/runFancy/${eventId}`);
  }

  closeEvent(sportId, eventId) {
    return this.httpClient.get(`${this.baseUrl}/${sportId}/${eventId}`);
  }
  Loackallbets() {
    return this.httpClient.get(`${this.baseUrl}/lockAllBets`);
  }
  Unlockallbets() {
    return this.httpClient.get(`${this.baseUrl}/unlockAllBets`);
  }
  LockTransfer() {
    return this.httpClient.get(`${this.baseUrl}/lockTransfer`);
  }
  UnlockTransfer() {
    return this.httpClient.get(`${this.baseUrl}/unlockTransfer`);
  }
  BetTransferStatus() {
    return this.httpClient.get(`${this.baseUrl}/betTransferStatus`);
  }
  lockRunner(data){
    return this.httpClient.post(`${this.baseUrl}/lockRunner`,data);
  }
  RestartGM(){
    return this.httpClient.get(`${this.baseUrl}/restartGM`);
  }
  getLiquidity(){
    return this.httpClient.get(`${this.baseUrl}/getLiquidity`);
  }
  setLiquidity(soccer,tennis){
    return this.httpClient.post(`${this.baseUrl}/setLiquidity?soccer=${soccer}&tennis=${tennis}`,{});
  }

  lockEvent(eventId){
    return this.httpClient.get(`${this.baseUrl}/lockEvent/${eventId}`);
  }
  unLockEvent(eventId){
    return this.httpClient.get(`${this.baseUrl}/unLockEvent/${eventId}`);
  }
  Fancytype(data){    
    return this.httpClient.get(`https://api.sportbet.id:8444/api/getAllFancymarkets/${data.eventid}/${data.status}`);
  }

}
