<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        Fancy Type
        <a class="btn btn-warning btn-sm" style="float: right;" title="Reload Fancy Records"><i
                class="fa fa-refresh fa-spin"></i>
        </a>
    </mat-card-header>
    <mat-card-content>
        <form>
            <div class="row">
                <div class="col-sm-3 col-xs-3 p-10">

                    <input type="text" placeholder="Enetr EventID" id="eventid" [(ngModel)]="eventId" name="eventid" required class="form-control" >
                </div>
                <div class="col-sm-3 col-xs-3 p-10">

                    <select class="form-control" (change)="filterSport($event)">
                        <option [value]="2" selected>Select Fancy Type</option>
                        <option [value]="0">Diamond</option>
                        <option [value]="1">Sky</option>

                    </select>
                </div>
                <div class="col-sm-3 col-xs-3 p-10">
                    <a class="btn btn-warning btn-sm m-1" title="submit" (click)="onSubmit()" >
                        Submit
                    </a>
                </div>
            </div>
        </form>
        <hr>
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" id="fancytable" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr class="heading">
                        <th>EventId</th>
                        <th>Event Name</th>
                        <th>gameId</th>
                        <th>Market Name</th>
                        <th>Winner SelId</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let market of markets;let index=index">
                        <td>{{market.eventId}}</td>

                        <td style="text-align: center;font-weight: 700;">{{market.eventName}}<br></td>
                        <td>{{market.gameId}}</td>
                        <td>{{market.marketName}}</td>
                        <td>{{market.winnerSelId}}</td>
                        <td>
                            <a (click)="openreversesettlemodal(market.gameId)" class="btn btn-danger btn-sm"
                                title="reverse settle" data-toggle="modal" data-target="#settlereverse"
                                *ngIf="getusertype==2"><span>R</span>
                            </a>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <!-- reverse and add runs-->
        <div id="settlereverse" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span> Reverse Settle Fancy</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Enter Runs : </label>
                        </div>

                        <div class="popup_col_12 button-row ">
                            <form [formGroup]="runsForm" class="runform">
                                <input type="text" class="form-control" formControlName="runs" [(ngModel)]="runs">
                                <button class="btnnewcls" mat-raised-button color="primary" type="button"
                                    (click)="reverseSetteledFancy()">Update</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </mat-card-content>


</mat-card>