<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        Pending Markets
        <a class="btn btn-warning btn-sm" style="float: right;" title="Reload Fancy Records" (click)="pendingmarket()"><i
            class="fa fa-refresh fa-spin"></i>
    </a>
    </mat-card-header>
    <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm row-border hover" datatable
            [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr class="heading">
                    <th>EventId</th>
                    <th>Event Name</th>
                    <th>Market Id</th>
                    <th>Fancy Market Name</th>
                    <th>Market Name</th>
                </tr>
            </thead>
            <tbody>

                <!-- *ngFor="let user of userinfo;let index=index" -->
                <tr *ngFor="let market of mymarket;let index=index">
                    <td>{{market.eventId}}
                    </td>

                    <!-- {{user.domain_name}} -->
                    <td>{{market.eventName}}
                    </td>
                    <td>{{market.marketId}}
                    </td>
                    <td>{{market.fancymarketName}}
                    </td>
                    <td style="padding-bottom: 0px;"><span class="m-bg">
                        {{market.marketName}}
                        </span>
                    </td>
                    
                   
                </tr>
            </tbody>
        </table>
    </div>
</mat-card>