import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AllapiService } from '../Services/allapi.service';
import { NotificationService } from '../Services/notification.service';

@Component({
  selector: 'app-store-allapi',
  templateUrl: './store-allapi.component.html',
  styleUrls: ['./store-allapi.component.scss']
})
export class StoreAllapiComponent implements OnInit {
  StoreapiForm: FormGroup;
  Allapidata: any;
  devIp1: any;
  devAdminIp1: any;
  devAdminIp: any;
  devIp: any;
  ip: any;
  adminIp: any;
  sportApi: any;
  sportSocketApi: any;
  sslSportApi: any;
  sslsportSocketApi: any;
  sslsportSocketApiSun: any;
  sslSportApiSun: any;
  fancyApi: any;
  scoreApi: any;
  casinoApi: any;
  fanApi: any;
  casApi: any;
  racingApi: any;
  racingSocketApi: any;
  sslRacingApi: any;
  sslracingSocketApi: any;
  sslExchangeGamesApi: any;
  sawtakaAdmin: any;
  sawtakaClient: any;
  betswiz8Admin: any;
  betswiz8Client: any;
  dream247Admin: any;
  dream247Client: any;
  ssladmin: any;
  sslclient2: any;
  ssladmin1: any;
  sslclient: any;
  fsource: any;


  constructor(private Allapi: AllapiService, private fb: FormBuilder, private notifyService: NotificationService,) { }

  ngOnInit(): void {

    this.GetAllapi();
  }
  Addapi(e: any) {
    this.fsource = e;
    this.StoreApi();
  }

  StoreApi() {
    let data = {
      "devIp1": "http://51.195.220.62:82",
      "devAdminIp1": "http://51.195.220.62:81",
      "devAdminIp": "https://sawtaka.in/pad=81",
      "devIp": "https://sawtaka.in/pad=82",
      "ip": "http://136.244.79.114:82",
      "adminIp": "http://136.244.79.114:81",
      "sportApi": "http://209.250.242.175:33332",
      "sportSocketApi": "ws://209.250.242.175",
      "sslSportApi": "https://apisports.xyz",
      "sslsportSocketApi": "wss://apisports.xyz",
      "sslSportApiSun": "https://sun777.us",
      "sslsportSocketApiSun": "wss://sun777.us",
      "fancyApi": "https://streamingtv.fun:3440/api/bm_fancy",
      "scoreApi": "https://streamingtv.fun:3440",
      "casinoApi": "https://streamingtv.fun:3440/api",
      "fanApi": "https://access.streamingtv.fun:3440",
      "casApi": "https://access.streamingtv.fun:3440",
      "racingApi": "http://136.244.77.249:33333",
      "racingSocketApi": "ws://136.244.77.249",
      "sslRacingApi": "https://horseandgreysports.xyz",
      "sslracingSocketApi": "wss://horseandgreysports.xyz",
      "sslExchangeGamesApi": "https://horseandgreysports.xyz",
      "sawtakaAdmin": "https://globlethings.com/pad=81",
      "sawtakaClient": "https://betswiz8.com/pad=82",
      "betswiz8Admin": "https://globlethings.com/pad=81",
      "betswiz8Client": "https://betswiz8.com/pad=82",
      "dream247Admin": "https://globlethings.com/pad=81",
      "dream247Client": "https://betswiz8.com/pad=82",
      "ssladmin": "https://exchangegames.xyz/pad=81",
      "sslclient2": "https://exchangegames.xyz/pad=82",
      "ssladmin1": "https://globlethings.com/pad=81",
      "sslclient": "https://globlethings.com/pad=82",
      "fSource": this.fsource
    }

    this.Allapi.StoreApi(data).subscribe((resp) => {

      if (resp.result == "Data saved!") {
        this.notifyService.success(resp.result);
        this.GetAllapi();
      } else {
        this.notifyService.error(resp.result);
      }
    });

  }

  GetAllapi() {
    this.Allapi.GetAllapi().subscribe((resp) => {
      this.Allapidata = resp;
      

    })
  }

}
