import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PremiumService {
  APIURL=environment.APIURL;
  APIURL4= environment.APIURL4;

  constructor(private http: HttpClient) { }

  AddSpBook(data): Observable<any> {
    return this.http.post(`${this.APIURL4}/addSpBook`, data);
  }
  SettleSportBook(data): Observable<any> {
    return this.http.post(`${this.APIURL4}/settleSportBook`, data);
  }

  GetSportbookmarket(sportsId,status,eventId): Observable<any> {
    return this.http.get(`${this.APIURL4}/getSportbookmarket/${sportsId}/${status}/${eventId}`);
  }

  GeteventsbySports(sportsId): Observable<any> {
    return this.http.get(`${this.APIURL4}/geteventsbySports/${sportsId}`);
  }

}
