<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        Pending Casino
        <a class="btn btn-warning btn-sm" style="float: right;" title="Reload Fancy Records" (click)="pendingcasino()"><i
            class="fa fa-refresh fa-spin"></i>
    </a>
    </mat-card-header>
    <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm row-border hover" datatable
            [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr class="heading">
                    <th>Table Name</th>
                    <th>Round Id</th>
                </tr>
            </thead>
            <tbody>


                <tr *ngFor="let casino of mycasino;let index=index">
                    <td>{{casino.tableName}}
                    </td>
                    <td>{{casino.roundId}}
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</mat-card>