<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        <a class="btn btn-info btn-sm btn-sm" data-toggle="modal" style="float: left;" data-target="#fancymodal"
            title="Add Fancy Details"><i class="fa fa-plus"></i>
        </a>
        List
        <a class="btn btn-warning btn-sm" style="float: right;" title="Reload Fancy Records" (click)="getFancyList()"><i
                class="fa fa-refresh fa-spin"></i>
        </a>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-sm-3 col-xs-3 p-10">
                <ng-select [(ngModel)]="selectedSport" (ngModelChange)="getHighlights()">
                    <ng-option *ngFor="let sport of sports" [value]="sport.sportbfid">{{sport.name}}</ng-option>
                </ng-select>
            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <ng-select  [(ngModel)]="selectedevent" (ngModelChange)="getFancyList()">
                    <ng-option value="" selected="selected">---- Select Eventname ----</ng-option>
                    <ng-option *ngFor="let high of highlights;trackBy:trckbyeventid" [value]="high.eventid">{{high.eventname}} /
                        {{high.eventdate.split("T")[0]}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <ng-select [(ngModel)]="selectedStatus" (ngModelChange)="getFancyList()">
                    <ng-option *ngFor="let option of options" [value]="option.status">{{option.type}}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="row" style="display: none;">
            <div class="col-sm-3 col-xs-3 p-10">
                <input type="text" placeholder="From Date" class="form-control" bsDatepicker
                    [(ngModel)]="selectfromdate" autocomplete="off"  readonly="true"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-default', isAnimated: true }">
            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <input type="text" placeholder="To Date" class="form-control" bsDatepicker
                    [(ngModel)]="selecttodate" autocomplete="off"  readonly="true"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-default', isAnimated: true }">
            </div>
        </div>
        <hr>
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" id="fancytable" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr class="heading">
                        <th>Id</th>
                        <th>Event ID</th>
                        <th>Market Name</th>
                        <th>{{(selectedStatus!='0')?"Settled":"Settle Market"}}</th>
                        <th>Eventname</th>
                        <th>Status</th>
                        <th>Action </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fancy of fancyList;let index=index;trackBy:trackfancyid">
                        <td>{{fancy.sbid}}</td>
                        <td>{{fancy.eventId}}</td>
                        <td style="text-align: center;font-weight: 700;">{{fancy.sbmarketname}}<br><span
                                style="font-size: 10px">{{fancy.eventDate.split("T")[0]}}</span></td>
                        <td>
                            <div style="display: flex;">
                                <div style="flex: 1;">
                                    <ng-select [(ngModel)]="fancy.result" *ngIf="fancy.status==0">
                                        <ng-option value="" selected="selected">Select Runner</ng-option>
                                        <ng-option  *ngFor="let runner of fancy.sbrunners" [disabled]="fancy.status!=0" [value]="runner">{{runner!=null?runner.selectionName:runner}}</ng-option>
                                    </ng-select>
                                    <td *ngIf="fancy.status==1 || fancy.status==2">{{fancy.result}} - {{fancy.selectionId}}</td>
                                </div>
                                <div style="margin: 3px;">
                                    <a class="btn btn-warning btn-sm" title="Settle Fancy" (click)="SettleBook(fancy)" *ngIf="fancy.status==0"><span>Settle</span></a>
                                    <a class="btn btn-warning btn-sm" title="Reverse Fancy" *ngIf="fancy.status==1 || fancy.status==2"
                                        (click)="fancyinfodata(fancy)" data-toggle="modal" data-target="#Confirmreversemodal"><span>Reverse</span>
                                    </a>
                                </div>
                            </div>
                        </td>
                        <td style="text-align: center;font-weight: 700;">{{fancy.eventName}}<br><span
                                style="font-size: 10px">{{fancy.eventDate.split("T")[0]}}</span></td>
                        <td><a class="btn btn-sm"
                                [ngClass]="{'btn-info':(fancy.status==0),'btn-success':(fancy.status==2 || fancy.status==1)}"
                                title="Status"><span>{{(fancy.status==0)?'ACTIVE':'SETTLED'}}</span></a></td>
                        <td>
                            <a class="btn btn-info btn-sm" title="Add Runner" *ngIf="fancy.status==0"><span><i
                                        class="fa fa-plus" aria-hidden="true"></i></span>
                            </a>
                            <a style="display: none;"  class="btn btn-info btn-sm" title="Verify Fancy" *ngIf="fancy.CompitionName!=null"
                                (click)="verifyfancyresult(fancy)"><span>{{fancy.CompitionName}}</span>
                            </a>
                            <a style="display: none;" class="btn btn-info btn-sm" title="Verify Fancy" *ngIf="fancy.CompitionName==null"
                                (click)="verifyfancyresult(fancy)"><span>Verify</span>
                            </a>
                            <a class="btn btn-danger btn-sm" data-toggle="modal" data-target="#Confirmcancelmodal" title="Cancel Fancy" *ngIf="fancy.status==0"
                                (click)="fancyinfodata(fancy)"><span>Cancel</span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Fancy Add -->
        <div id="fancymodal" class="modal fade" role="dialog" aria-labelledby="fancymodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Fancy Details</span>
                        <button type="button" class="close" (click)="resetForm()" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="popup_form_row" *ngIf="AddFancyForm">
                            <form [formGroup]="AddFancyForm" (ngSubmit)="addMarket()">
                                <div>
                                    <label> Sport Name : </label>
                                    <ng-select formControlName="sportName" [(ngModel)]="selectedSportToAdd" (ngModelChange)="getHighlightsToAdd()" >
                                        <ng-option value="" selected="selected">---- Select Sport ----</ng-option>
                                        <ng-option *ngFor="let sport of sportsAdd" [value]="sport.sportbfid">{{sport.name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div>
                                    <label> Event Name : </label>
                                    <ng-select formControlName="eventname" [(ngModel)]="selectedeventToAdd">
                                        <ng-option value="" selected="selected">---- Select Event ----</ng-option>
                                        <ng-option *ngFor="let high of highlightsAdd;trackBy:trckbyeventid" [value]="high">{{high.eventname}} /
                                            {{high.eventdate.split("T")[0]}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div>
                                    <label> Market Name : </label>
                                    <input type="text" class="form-control" formControlName="marketname">
                                </div>
                                <div class="popup_col_12 button-row">
                                    <button mat-raised-button color="primary" [disabled]="AddFancyForm.invalid"
                                        type="submit">Add</button>
                                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                                        type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete all Confirm Info -->
        <div id="Confirmdeleteallmodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to delete All Fancies ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete Confirm Info -->
        <div id="Confirmdeletemodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to delete Fancy ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- reverse Confirm Info -->
        <div id="Confirmreversemodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>
        
                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to reverse Fancy ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button" (click)="reversefancy(fancydata)">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- cancel Confirm Info -->
        <div id="Confirmcancelmodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>
        
                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to cancel Fancy ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button" (click)="cancelfancy(fancydata)">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>