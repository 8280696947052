import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AmanfancyService {
  BASEURL = environment.BASEURL1;
  APIURL=environment.APIURL;
  constructor(private http: HttpClient) { }

  GetAmanfancyList(status,eventid,source): Observable<any> {
    return this.http.get(`${this.BASEURL}/GetAmanfancyList?status=${status}&eventid=${eventid}&source=${source}`);
  }

  AddAmanFancy(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/AddAmanFancy`, data);
  }
  AddAmanFancy2(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/AddAmanFancy2`, data);
  }
  UpdateAmanFancyResult(data): Observable<any> {
    return this.http.put(`${this.BASEURL}/updateAmanFancyResult`, data);
  }
  updateAmanFancyStatus(data): Observable<any> {
    return this.http.put(`${this.BASEURL}/updateAmanFancyStatus`, data);
  }

  GetTKAllevents(): Observable<any> {
    return this.http.get(`${this.BASEURL}/GetTKAllevents`);
  }
  GetAmanAllevents(): Observable<any> {
    return this.http.get(`${this.BASEURL}/GetAmanAllevents`);
  }

  Verifyfancyresult(eventid,fancynamme,status): Observable<any> {
    return this.http.get(`${this.APIURL}/api/fancy_result/${eventid}/${fancynamme}/${status}`);
  }
  Addfancyresult(data): Observable<any> {
    return this.http.post(`${this.APIURL}/api/store_fancy_result`, data);
  }
}
